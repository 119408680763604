import { Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { TableComponent } from '@components/common/table-component';
import { ContainerResource } from '@resources/container-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'container-details-tab',
    templateUrl: './container-details-tab.html',
    styleUrls: ['./container-details-tab.scss'],
})
export class ContainerDetailsTab extends TableComponent {
    @ViewChild(MatSort) sort: MatSort;
    hasData: boolean = false;
    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = [
        'drug_name',
        'manufacturer',
        'ndc',
        'epc',
        'strength',
        'package',
        'lot',
        'expiration',
    ];

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private containerResource: ContainerResource,
        private translationService: TranslationService
    ) {
        super();
    }

    get containerDetailTableHeading() {
        const containerSegmentsLength = this.dataSource?.data?.length || 0;
        const itemTranslation = this.translationService.instant('containers.container_details.item_title');

        return `${containerSegmentsLength} ${itemTranslation}${containerSegmentsLength > 1 ? 's' : ''}`;
    }

    ngAfterViewInit(): void {
        this.containerResource.getContainerSegmentsScanData(1).then((segments: any) => {
            this.dataSource = new MatTableDataSourceWithNaturalSort(segments);
            this.dataSource.sort = this.sort;
            this.hasData = this.dataSource?.data.length > 0;
        });
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }
}
