import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { StateService } from '@uirouter/core';
import { ApplicationService } from '@services/system/application.service';
import { BinResource } from '@resources/bin-resource.service';
import { HospitalInfoService } from '@services/core/hospital-info.service';
import { isAppUnderPostLockoutState, isScannedAfterLockoutState } from '@utils/charge-model-util';
import { ChargeModelTypeName } from '@models/core/hospital-settings';

import * as naturalSort from 'object-property-natural-sort/property-sort';

@Component({
    selector: 'bin-edit',
    templateUrl: './bin-edit.html',
    styleUrls: ['./bin-edit.scss'],
})
export class BinEdit {
    @Input() binScan;

    originalBin: any;
    counts: any;
    items: Array<any>;
    tab = 'summary';
    filteredItems: Array<any>;
    formulary: Array<any>;
    generic: Array<any>;
    custom: Array<any>;
    wrong: Array<any>;
    soonest: any;
    binDetails: Array<any>;
    expiringSoonDetails: Array<any>;
    subscription_items_lockout_date: string;
    charge_model_type: ChargeModelTypeName;
    postLockout: boolean = false;
    requiredSubscriptionSegmentItems: Array<any> = [];

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private $state: StateService,
        private applicationService: ApplicationService,
        private binResource: BinResource,
        protected hospitalInfoService: HospitalInfoService
    ) {
        this.applicationService.subheaderTitle = undefined;

        // Sets naturalSort method to case insensitive
        naturalSort.insensitive = true;
    }

    ngOnInit() {
        this.originalBin = _.cloneDeep(this.binScan.bin);
        this.counts = this.binScan.counts;
        this.formularyAndClinicalEquivalenceItems();
        this.formulary = this.binScan.items.by_formulary_items;
        this.generic = this.binScan.items.by_clinical_equivalences;
        this.custom = this.binScan.items.by_missing_clinical_equivalences;
        this.wrong = this.binScan.items.wrong;
        this.soonest = this.getSoonestExpiration();
        this.binDetails = this.binScan.segment.items;
        this.expiringSoonDetails = this.binScan.items;

        this.subscription_items_lockout_date =
            this.hospitalInfoService.getHospitalSettings().subscription_items_lockout_date;
        this.charge_model_type = this.hospitalInfoService.getHospitalSettings().charge_model_type.name;
        this.postLockout = isAppUnderPostLockoutState(this.subscription_items_lockout_date, this.charge_model_type);

        this.requiredSubscriptionSegmentItems = this.binDetails.filter((item) => item.subscription_required);
        this.filterSubscriptionItems();
    }

    filterSubscriptionItems() {
        if (
            this.postLockout &&
            !!this.binScan?.bin?.last_bin_scan?.created_at &&
            isScannedAfterLockoutState(
                this.subscription_items_lockout_date,
                this.binScan?.bin?.last_bin_scan?.created_at
            )
        ) {
            this.wrong = this.wrong.filter((item) => !item.subscription_required);
            this.binDetails = this.binDetails.filter((item) => !item.subscription_required);
        }
    }

    formularyAndClinicalEquivalenceItems(): void {
        this.items = [...this.binScan.items.by_clinical_equivalences, ...this.binScan.items.by_formulary_items];
    }

    updateBinScan(data: any): void {
        this.counts = data.counts;
        this.formularyAndClinicalEquivalenceItems();
        this.formulary = data.items.by_formulary_items;
        this.generic = data.items.by_clinical_equivalences;
        this.custom = data.items.by_missing_clinical_equivalences;
        this.wrong = data.items.wrong;
        this.soonest = this.getSoonestExpiration();
        this.binDetails = data.segment.items;
        this.expiringSoonDetails = data.items;
        this.binScan.bin.segment_template_clinical_equivalences = data.bin.segment_template_clinical_equivalences;
        this.binScan.bin.segment_template_formulary_items = data.bin.segment_template_formulary_items;
        this.filterSubscriptionItems();
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }

    disableUpdate(): boolean {
        return (
            this.originalBin.name === this.binScan.bin.name &&
            this.originalBin.min_par_level === this.binScan.bin.min_par_level &&
            this.originalBin.max_par_level === this.binScan.bin.max_par_level &&
            this.originalBin.expiring_soon_days === this.binScan.bin.expiring_soon_days
        );
    }

    cancel(): void {
        this.$state.go('bins-index');
    }

    submit(): void {
        const data = {
            name: this.binScan.bin.name,
            min_par_level: this.binScan.bin.min_par_level,
            max_par_level: this.binScan.bin.max_par_level,
            expiring_soon_days: this.binScan.bin.expiring_soon_days,
        };

        this.binResource.updateBin(this.binScan.bin.id, data).then(() => {
            this.$state.go('bins-index');
        });
    }

    private getSoonestExpiration(): any {
        const { expirations } = this.binScan;

        if (expirations && expirations.soonest) {
            // find expiration.dates key and insert new key expiration set it to expiration.soonest and return it
            //the returned object looks like this {items:[], expiration: '2019-08-01', expiration_formatted:'Aug 01, 2019'}
            return _.find(expirations.dates, {
                expiration: expirations.soonest,
            });
        }
    }

    return;
}
