import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContainerForm } from '@models/core/container';
import { Location } from '@models/core/location';
import { Scanner } from '@models/hardware/scanner';
import { TranslateService } from '@ngx-translate/core';
import { ParseFormErrorsService } from '@services/utils/parse-form-errors.service';

type ContainerDialogData = { hardware: Scanner } & ContainerForm;

@Component({
    selector: 'manage-container-dialog',
    templateUrl: './manage-container-dialog.html',
    styleUrls: ['./manage-container-dialog.scss'],
})
export class ManageContainerDialog {
    errorMessage: string;
    hardware: Scanner;
    container: ContainerDialogData = {
        type: 'refrigerator',
        name: '',
        location_id: null,
        hardware_id: null,
        hardware: null,
    };
    locations: Location[];
    modalTitle: string;
    submitButtonText: string;

    constructor(
        public dialogRef: MatDialogRef<ManageContainerDialog, ContainerForm>,
        private parseFormErrorsService: ParseFormErrorsService,
        private translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    ngOnInit() {
        if (this.data.containerForm) {
            this.modalTitle = this.translateService.instant('containers.modals.manage_modal_dialog.edit_container');
            this.submitButtonText = this.translateService.instant(
                'containers.modals.manage_modal_dialog.save_container_button'
            );
            this.container = { ...this.data.containerForm }; // spread operator avoids assigning the object reference
            return;
        }

        this.modalTitle = this.translateService.instant('containers.modals.manage_modal_dialog.add_container');
        this.submitButtonText = this.translateService.instant(
            'containers.modals.manage_modal_dialog.create_container_button'
        );
    }

    onCancel() {
        this.dialogRef.close();
    }

    submit() {
        const containerData = {
            name: this.container.name,
            type: this.container.type,
            location_id: this.container.location_id,
            hardware_id: this.container.hardware_id,
        };

        this.dialogRef.close(containerData);
    }

    parseErrors(errors) {
        return this.parseFormErrorsService.parseErrors(errors);
    }

    compareScanners(o1: any, o2: any): boolean {
        return o1.id === o2?.id;
    }

    changeScanner(event) {
        this.container.hardware_id = event.id;
    }
}
