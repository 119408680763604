import {
    Component,
    Input,
    OnInit,
    AfterViewInit,
    ViewChild,
    ElementRef,
    Injector,
    Injectable,
    NgZone,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment';

import { MatDialog } from '@angular/material/dialog';

import * as naturalSort from 'object-property-natural-sort/property-sort';
import { KitScanResource } from '@resources/kit-scan-resource.service';
import { KitResource } from '@resources/kit-resource.service';
import { SegmentsService } from '@services/core/segments.service';
import { ConfirmDialog } from '@components/dialogs/confirm/confirm-dialog';
import * as CHARGE_TEMPLATES from '@components/charge-sheet-templates';
import { TemplateData } from '@components/charge-sheet-templates/templates/template-data';
import { titleize } from '@utils/strings';
import { printPage } from '@utils/print';

@Component({
    selector: 'charge-sheet-test',
    templateUrl: './charge-sheet-test.html',
})
export class ChargeSheetTest {
    @Input() kits;
    @Input() kitMasters;

    searchUpdated: Subject<string> = new Subject<string>();
    filteredKits: Array<any>;
    kitSummary;
    chargeData: any;
    chargeTemplate;
    templateComponent;
    templateInjector: Injector;

    filter;
    sort = {
        field: 'last_scan_created_at',
        reverse: true,
    } as any;

    importingStatus: string = null;

    constructor(
        private kitScanResource: KitScanResource,
        protected kitResource: KitResource,
        protected segmentsService: SegmentsService,
        private injector: Injector,
        private dialog: MatDialog,
        private zone: NgZone
    ) {
        naturalSort.insensitive = true;
        this.searchUpdated
            .pipe(
                debounceTime(500), // wait x ms after the last event before emitting last event
                distinctUntilChanged() // only emit if value is different from previous value
            )
            .subscribe(() => this.filterKits());
    }

    ngOnInit() {
        this.setupKits();
        this.filteredKits = this.kits;
        this.sortKits();
    }

    ngAfterViewInit() {}

    setupKits() {
        this.kits.forEach((kit) => {
            kit.kit_master_name = kit.kit_master?.name;
            kit.kit_master_id = `${kit.kit_master?.id}`;
        });
    }
    onSearchTyped(value: string) {
        this.searchUpdated.next(value);
    }

    filterKits() {
        this.zone.runOutsideAngular(() => {
            this.filteredKits = this.kits.filter(this.kitSearch);

            this.sortKits();
        });
    }

    kitSearch = (kit) => {
        if (
            this.searchMatch(kit, 'physical_label') ||
            this.searchMatch(kit, 'kit_master_name') ||
            this.searchMatch(kit, 'cart_name') ||
            this.searchMatch(kit, 'kit_master_id')
        ) {
            return true;
        } else {
            return false;
        }
    };

    searchMatch = (item, attribute) => {
        return item[attribute] && item[attribute].toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
    };

    sortKits() {
        this.zone.runOutsideAngular(() => {
            let sortedKits = this.filteredKits.sort(naturalSort(this.sort.field));

            this.filteredKits = this.sort.reverse ? sortedKits.reverse() : sortedKits;
        });
    }

    getChargeData(kit) {
        this.kitScanResource.kitSummary(kit.id, kit.last_scan.id).then((kitSummary) => {
            this.kitSummary = kitSummary;
            this.chargeData = null;

            this.kitResource
                .kitPrintChargeSheet(kit.id, kit.last_scan.id)
                .then((data) => {
                    this.chargeData = data;
                    this.chargeData.title = `${data.kit_master.name}: ${data.kit.physical_label}`;
                    this.chargeData.cleaned = data.completed_dispatch_validations.find(
                        (validation) => validation === 'kit_cleaned'
                    );
                    this.chargeData.scannedByUser = this.determineScanUsername(data.scan);

                    if (data.expirations && data.expirations.soonest) {
                        this.chargeData.soonest = _.find(data.expirations.dates, {
                            expiration: data.expirations.soonest,
                        });
                    }
                    _.each(this.chargeData.segments, (segment: any) => {
                        segment.items = _.sortBy(segment.items, (item: any) => moment(item.expiration).toDate());
                        segment.extra = _.sortBy(segment.extra, (item: any) => moment(item.expiration).toDate());
                        segment.itemCount = segment.items.length + segment.extra.length;

                        //Creates an array of formatted strings to display the alternate fill options
                        this.segmentsService.setAlternateFillFormatted(segment);

                        segment.firstItem = segment.items[0] || segment.extra[0];
                        if (segment.items.length) {
                            segment.items.splice(0, 1);
                        } else {
                            segment.extra.splice(0, 1);
                        }

                        segment.text = `Shortage: ${segment.itemCount} of ${segment.expected}`;
                    });

                    if (this.chargeData.locks) {
                        this.chargeData.lockGroups = this.locksToGroups(this.chargeData.locks);
                    }

                    this.templateInjector = Injector.create(
                        [
                            {
                                provide: TemplateData,
                                useValue: { chargeData: this.chargeData, kitSummary: this.kitSummary },
                            },
                        ],
                        this.injector
                    );

                    this.chargeTemplate =
                        data.chargesheet_template === 'Default'
                            ? 'Charge'
                            : this.componentName(data.chargesheet_template);

                    this.templateComponent = CHARGE_TEMPLATES.ChargeSheetTemplates.find(
                        (template) => template.name === this.chargeTemplate
                    );
                    // default to compact template if the template was not that found
                    if (!this.templateComponent) {
                        this.chargeTemplate = 'Compact';
                        this.templateComponent = CHARGE_TEMPLATES.ChargeSheetTemplates.find(
                            (template) => template.name === this.chargeTemplate
                        );
                    }
                })
                .finally(() => {
                    // intentional console log:
                    console.log('Kit Data: ', this.chargeData);

                    if (this.chargeTemplate) {
                        const confirmDialog = this.dialog.open(ConfirmDialog, {
                            width: '600px',
                            height: 'max-content',
                            data: {
                                title: `Print Charge Sheet for ${this.chargeData.kit.kit_master?.name}`,
                                description: `Charge sheet template in use: ${this.chargeTemplate}`,
                                detail: 'Check the console for full details for this kit',
                                okButton: 'Print',
                            },
                        });

                        confirmDialog.afterClosed().subscribe((confirmed) => {
                            if (confirmed) {
                                this.printChargeSheet();
                            }
                        });
                    } else {
                        alert('There is an issue with this kit. Cannot print charge sheet.');
                    }
                });
        });
    }

    printChargeSheet() {
        printPage('print-charge');
    }

    locksToGroups(locks) {
        let i = 0;
        let group = [];
        const lockGroups = [];
        const numLockGroups = Math.min(3, Math.ceil(locks.length / 2));

        _.each(_.range(numLockGroups), () => {
            group = [];
            lockGroups.push(group);
        });

        _.each(locks, (lock) => {
            if (i === numLockGroups) {
                i = 0;
            }

            lockGroups[i].push(lock);
            i++;
        });

        return lockGroups;
    }

    determineScanUsername(scan_data) {
        let scannedByUser = scan_data.created_by;
        if (scan_data.is_pharmacist) {
            scannedByUser += ', Pharmacist';
        }

        return scannedByUser;
    }

    // converst a file_name to a ComponentName
    componentName(str) {
        if (str) {
            return titleize(_.camelCase(str), false);
        }
        return 'Compact';
    }
}
