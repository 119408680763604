import { Component, Input } from '@angular/core';
import * as naturalSort from 'object-property-natural-sort/property-sort';

@Component({
    selector: 'multi-kit-pick-list',
    templateUrl: './multi-kit-pick-list.html',
})
export class MultiKitPickList {
    @Input() kitData;
    @Input() hospitalLogo;

    constructor() {}
    naturalSort(segments) {
        return segments.sort(naturalSort('name'));
    }
}
