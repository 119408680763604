import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'expiring-chip',
    templateUrl: './expiring-chip.html',
    styleUrls: ['./expiring-chip.scss'],
})
export class ExpiringChip {
    @Input() expiration: string;
    @Input() expiringSoon: boolean;
    @Input() hasExpired: boolean;

    getChipColor() {
        if (this.expiringSoon) {
            return 'warn';
        }

        if (this.hasExpired) {
            return 'danger';
        }
    }

    getFormattedExpiration(expirationDate: string) {
        const formattedDate = moment(expirationDate).format('MMM DD, YYYY');

        return formattedDate;
    }
}
