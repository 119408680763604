import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { TableComponent } from '@components/common/table-component';
import { RemoveSegmentDialog } from '@components/dialogs/remove-segment-dialog/remove-segment-dialog';
import {
    SegmentResourceType,
    SegmentTemplateEditDialog,
} from '@components/dialogs/segment-template-edit/segment-template-edit-dialog';
import { Container } from '@models/core/container';
import { Segment } from '@models/core/segment';
import { SegmentTemplate } from '@models/core/segment-template';
import { SegmentResource } from '@resources/segment-resource.service';
import { FormularyItemsService } from '@services/core/formulary-items.service';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { ActionService } from '@services/utils/action.service';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'container-summary-tab',
    templateUrl: './container-summary-tab.html',
    styleUrls: ['./container-summary-tab.scss'],
})
export class ContainerSummaryTab extends TableComponent {
    @Input() containerData: Container;
    @Output() onSegmentSave = new EventEmitter<void>();
    @Output() onSegmentDelete = new EventEmitter<void>();
    @ViewChild(MatSort) sort: MatSort;
    dataSource: MatTableDataSourceWithNaturalSort<Segment>;
    hasData: boolean;
    displayedColumns: string[] = ['name', 'containerActions'];
    isCreateContainerSegmentAllowed: boolean;
    isDeleteContainerSegmentAllowed: boolean;

    constructor(
        private dialog: MatDialog,
        private loadingSpinnerService: LoadingSpinnerService,
        private formularyItemsService: FormularyItemsService,
        private $state: StateService,
        private segmentResource: SegmentResource,
        private actionService: ActionService
    ) {
        super();
    }

    ngOnInit() {
        this.isCreateContainerSegmentAllowed = this.actionService.isAllowAction(
            'kits_inventory',
            'create_container_segment',
            'Show New Segment button'
        );
        this.isDeleteContainerSegmentAllowed = this.actionService.isAllowAction(
            'kits_inventory',
            'delete_container_segment',
            'Show Delete Segment button'
        );

        if (!!this.containerData) {
            this.updateData(this.containerData.segments);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes && changes.hasOwnProperty('containerData')) {
            this.updateData(changes.containerData.currentValue['segments']);
        }
    }

    openContainerSegmentModal(segment?: Segment, segmentTemplate?: SegmentTemplate) {
        const containerId = this.$state.params.id;
        this.loadingSpinnerService
            .spinnerifyPromise(this.formularyItemsService.getFormularyItems(), LoadingSpinnerTypes.BACKDROP)
            .then((formularyItems) => {
                const dialogConfirm = this.dialog.open(SegmentTemplateEditDialog, {
                    width: '900px',
                    height: 'max-content',
                    data: {
                        formularyItems,
                        segment,
                        segments: [],
                        segmentTemplate,
                        resource: SegmentResourceType.container,
                        containerId,
                    },
                    autoFocus: false,
                });

                dialogConfirm.afterClosed().subscribe((confirmSave) => {
                    if (confirmSave) {
                        this.onSegmentSave.emit();
                    }
                });
            });
    }

    removeSegment(segmentId: number, segmentName: string) {
        const confirmDialog = this.dialog.open(RemoveSegmentDialog, {
            width: '460px',
            height: 'max-content',
            data: {
                segmentName,
            },
            autoFocus: false,
        });

        confirmDialog.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.loadingSpinnerService.spinnerifyPromise(this.segmentResource.removeSegment(segmentId));

                this.onSegmentDelete.emit();
            }
        });
    }

    updateData(segments: Segment[]) {
        this.dataSource = new MatTableDataSourceWithNaturalSort(segments);
        this.dataSource.sort = this.sort;
        this.hasData = this.dataSource?.data.length > 0;
    }
}
