import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { TableComponent } from '@components/common/table-component';
import {
    ContainerLocationForm,
    EditContainerLocationDialog,
} from '@components/dialogs/edit-container-location-dialog/edit-container-location-dialog';
import { ManageContainerDialog } from '@components/dialogs/manage-container-dialog/manage-container-dialog';
import { Container, ContainerForm } from '@models/core/container';
import { Location } from '@models/core/location';
import { Scanner } from '@models/hardware/scanner';
import { ContainerResource } from '@resources/container-resource.service';
import { LocationResource } from '@resources/location-resource.service';
import { ApplicationService } from '@services/system/application.service';
import { LoadingSpinnerService, LoadingSpinnerTypes } from '@services/system/loading-spinner.service';
import { ActionService } from '@services/utils/action.service';
import { KCMatSnackBarService, SnackBarTypes } from '@services/utils/kc-mat-snack-bar.service';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { TranslationService } from '@services/utils/translation.service';

@Component({
    selector: 'containers-index',
    templateUrl: './containers-index.html',
    styleUrls: ['./containers-index.scss'],
})
export class ContainersIndex extends TableComponent {
    @Input() scanners: Scanner[];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('searchInput') searchInput: ElementRef;
    formularySearch: string = '';
    showSearch: boolean = false;
    hasData: boolean = false;
    dataSource: MatTableDataSourceWithNaturalSort<Container>;
    filteredItems: Container[] = [];

    displayedColumns: string[] = [
        'name',
        'type',
        'last_scan_date',
        'below_par',
        'quantity',
        'expired_count',
        'expiring_soon',
        'first_expiring',
        'location',
        'containerActions',
    ];
    locations: any;
    containers: Container[];
    isContainerEditAllowed: boolean;
    isContainerDeleteAllowed: boolean;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private dialog: MatDialog,
        private locationResource: LocationResource,
        private containerResource: ContainerResource,
        private translationService: TranslationService,
        private kcMatSnackBarService: KCMatSnackBarService,
        private actionService: ActionService
    ) {
        super();
    }

    get containersTableHeading() {
        const dataLength = this.dataSource?.data?.length || 0;
        const singularContainer = this.translationService.instant('containers.singular_container');
        return `${dataLength} ${singularContainer}${dataLength !== 1 ? 's' : ''}`;
    }

    ngAfterViewInit(): void {
        this.loadingSpinnerService.spinnerifyPromise(this.loadContainers());
        this.isContainerEditAllowed = this.actionService.isAllowAction(
            'kits_inventory',
            'update_kit_master',
            'Show container edit button'
        );
        this.isContainerDeleteAllowed = this.actionService.isAllowAction(
            'kits_inventory',
            'delete_kit_master',
            'Show container delete button'
        );
    }

    loadContainers() {
        return Promise.all([
            this.containerResource.getContainers('refrigerator'),
            this.locationResource.locationList(false),
        ]).then(([{ containers }, { locations }]) => {
            this.filteredItems = containers;
            this.locations = locations;

            this.dataSource = new MatTableDataSourceWithNaturalSort(containers);
            this.dataSource.sort = this.sort;
            this.hasData = this.dataSource?.data.length > 0;
        });
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }

    toggleSearch(show): void {
        if (this.formularySearch !== '') {
            this.showSearch = true;
        } else {
            this.showSearch = show;
        }
        if (this.showSearch) {
            setTimeout(() => {
                this.searchInput.nativeElement.focus();
            });
        }
    }

    filterItems() {
        if (this.formularySearch === '') {
            this.dataSource = new MatTableDataSourceWithNaturalSort(this.filteredItems);
            return;
        }

        const containersData = this.filteredItems.filter((container) =>
            container.name.toLowerCase().includes(this.formularySearch.toLowerCase())
        );

        this.dataSource = new MatTableDataSourceWithNaturalSort(containersData);
    }

    addContainer() {
        const addDialog = this.dialog.open(ManageContainerDialog, {
            width: '460px',
            height: 'max-content',
            data: {
                scanners: this.scanners,
                locations: this.locations,
            },
            autoFocus: false,
        });

        addDialog.afterClosed().subscribe((dialogData: ContainerForm) => {
            if (dialogData) {
                this.loadingSpinnerService.spinnerifyPromise(
                    this.containerResource.addContainer(dialogData).then(() => this.loadContainers())
                );
            }
        });
    }

    editContainer(container: Container) {
        const editDialog = this.dialog.open(ManageContainerDialog, {
            width: '460px',
            height: 'max-content',
            data: {
                containerForm: {
                    name: container.name,
                    location_id: container.location?.id,
                    hardware: container.hardware,
                    type: container.type.toLowerCase(),
                },
                scanners: this.scanners,
                locations: this.locations,
            },
            autoFocus: false,
        });

        editDialog.afterClosed().subscribe((dialogData: ContainerForm) => {
            if (dialogData) {
                this.loadingSpinnerService.spinnerifyPromise(
                    this.containerResource.editContainer(container.id, dialogData).then(() => this.loadContainers())
                );
            }
        });
    }

    editLocation(container: Container) {
        const editLocationDialog = this.dialog.open(EditContainerLocationDialog, {
            width: '615px',
            height: 'max-content',
            data: {
                locations: this.locations,
                containerTable: [{ name: container.name, location: container.location, type: container.type }],
            },
            autoFocus: false,
        });

        editLocationDialog.afterClosed().subscribe((dialogData: ContainerLocationForm) => {
            if (dialogData) {
                this.loadingSpinnerService.spinnerifyPromise(
                    this.containerResource
                        .editContainer(container.id, {
                            location_id: dialogData.container_location_id,
                            type: container.type.toLowerCase(),
                        })
                        .then(() =>
                            this.loadContainers().then(() =>
                                this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.SUCCESS, {
                                    key: 'containers.modals.manage_modal_dialog.location_success',
                                })
                            )
                        )
                        .catch(() =>
                            this.kcMatSnackBarService.openWithTranslate(SnackBarTypes.ERROR, {
                                key: 'containers.modals.manage_modal_dialog.location_error',
                            })
                        )
                );
            }
        });
    }
}
